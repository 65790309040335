import React from 'react';

import Layout from '../../components/layout/layout';
import SEO from '../../components/seo/seo';
import HomeownerFullSignup from '../../components/signup/homeownerFullSignup';
import MainWrapper from '../../components/ui/MainWrapper'

const HomeownerSignupPage = () => {
  return (
    <MainWrapper>
      <Layout backButton="/signup">
        <SEO title="Homeowner signup" keywords={['signup', 'homeowner']} canonicalUrl="https://app.fairmarkethome.com/signup/homeowner" />
        <HomeownerFullSignup accountType="Homeowner" />
      </Layout>
    </MainWrapper>
  );
}
export default HomeownerSignupPage;
